import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Product } from '../models/Product';
import { Template } from '../models/Template';
import { ProductStepData } from '../models/ProductStepData';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  //baseUrl = "https://nazal.in/digi/";
  baseUrl = "https://6141990d357db50017b3db83.mockapi.io/api/v1/"

  getListOfProducts(){
    return this.http.get<Product[]>(this.baseUrl + 'products');
  }

  getListOfTemplates(){
    return this.http.get<Template[]>(this.baseUrl + 'templates');
  }

  getStepData(productId){
    return this.http.get<ProductStepData[]>(this.baseUrl + 'stepData');
  } 

  validatePageName(pageName){
    const data = {
      pageName : pageName
    }
    return this.http.post<Boolean>(this.baseUrl + 'wp-json/api/v1/wp-269', data);
  } 

  uploadFile(fileData){
    const data = {
      image : fileData
    }
    return this.http.post<string>(this.baseUrl + 'wp-json/api/v1/wp-458', data);
  } 

  addDataToFileForPreview(fileData){
    const data = {
      image : fileData
    }
    return this.http.post<string>(this.baseUrl + 'wp-json/api/v1/wp-352', data);
  } 
}
