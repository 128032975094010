<div class="mobile-view" *ngIf="isSplashScreen">
  <div class="splashScreen mat-primary">
    <h2 style="color: #fff; font-size: 30px;">Easy Digital Card</h2>
  </div>
</div>
<div class="mobile-view" *ngIf="!isSplashScreen">
  <mat-drawer-container class="example-container">
    <mat-drawer #drawer mode="over" color="primary" [opened]="showSidenav">
      <mat-nav-list>
          <mat-list-item (click)="openPage('/user/profile')">
              <mat-icon mat-list-icon>folder</mat-icon>
              <div mat-line>Guest User</div>
              <div mat-line> How are you? </div>
          </mat-list-item>
          <mat-list-item (click)="openPage('/')" class="parent">
              <span class="full-width">Home</span>
              <mat-icon mat-list-icon>home</mat-icon>
          </mat-list-item>
          <mat-list-item (click)="openPage('/products')" class="parent">
              <span class="full-width">Products</span>
              <mat-icon mat-list-icon>home</mat-icon>
          </mat-list-item>
          <mat-list-item *ngIf="!isLoggedIn()" (click)="openPage('/user/login')" class="parent">
              <span class="full-width">Login</span>
              <mat-icon mat-list-icon>home</mat-icon>
          </mat-list-item>
          <mat-list-item *ngIf="!isLoggedIn()" (click)="openPage('/user/register')" class="parent">
              <span class="full-width">Register</span>
              <mat-icon mat-list-icon>home</mat-icon>
          </mat-list-item>
          <mat-list-item *ngIf="isLoggedIn()" (click)="openPage('/user/logout')" class="parent">
              <span class="full-width">Logout</span>
              <mat-icon mat-list-icon>home</mat-icon>
          </mat-list-item>
      </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content>
      <mat-toolbar color="primary" *ngIf="isShowToolbar">
        <button (click)="toggleNav()" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
          <mat-icon>menu</mat-icon>
        </button>
        <span>{{globals.title}}</span>
        <span class="example-spacer"></span>    
      </mat-toolbar>
      <router-outlet></router-outlet>   
    </mat-drawer-content>
  </mat-drawer-container> 
</div>

<div class="loadingScreen" *ngIf="loadScreen">
  <mat-spinner></mat-spinner>
</div>

<div class="toast" [ngClass]="{'showing': toastMessage != null}" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <i class="fa fa-danger"></i>
    <strong class="me-auto">Error</strong>
    <button type="button" class="btn-close" (click)="toastMessage = null"></button>
  </div>
  <div class="toast-body">
    {{toastMessage}}
  </div>
</div>