import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-handler',
  templateUrl: './item-handler.component.html',
  styleUrls: ['./item-handler.component.css']
})
export class ItemHandlerComponent implements OnInit {

  @Input("data")
  data: any;

  type: string;

  constructor() { }

  ngOnInit(): void {
    this.type = this.data.type + "-" + this.data.template;
  }

}
