import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductSingleComponent } from './product-single/product-single.component';
import { HttpClientModule } from '@angular/common/http';
import { Card1Component } from './templates/card/card1/card1.component';
import { ItemHandlerComponent } from './item-handler/item-handler.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatGridListModule} from '@angular/material/grid-list';

import { RepeaterDialogComponent } from './repeater-dialog/repeater-dialog.component';
import { Globals } from './globals';
import { PreviewDialogComponent } from './preview-dialog/preview-dialog.component';
import { Card2Component } from './templates/card/card2/card2.component';
import { Card3Component } from './templates/card/card3/card3.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { ProfileComponent } from './user/profile/profile.component';
import { FormsModule } from '@angular/forms';
import { LogoutComponent } from './user/logout/logout.component';


@NgModule({
  declarations: [
    AppComponent,
    ProductListComponent,
    ProductSingleComponent, 
    Card1Component,
    ItemHandlerComponent,
    RepeaterDialogComponent,
    PreviewDialogComponent,
    Card2Component,
    Card3Component,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatCardModule,
    MatListModule,
    MatDialogModule,
    MatToolbarModule,
    MatSidenavModule,
    MatGridListModule
  ],
  providers: [ Globals ],
  bootstrap: [AppComponent]
})
export class AppModule { }
