<div class="loginFullSection" color="primary">
    <div class="loginTopSection">
        <h2>Join Us</h2>
        <p>Lorem ipsum is simply dummy content to write anywhere on your website</p>
    </div>
    <div class="loginFormSection">
        <mat-form-field appearance="fill" >
            <mat-label>Username</mat-label>
            <input matInput>
        </mat-form-field>
        <mat-form-field appearance="fill" >
            <mat-label>Password</mat-label>
            <input matInput>
        </mat-form-field>
        <mat-form-field appearance="fill" >
            <mat-label>Confirm Password</mat-label>
            <input matInput>
        </mat-form-field>
        <button class="loginBtn" mat-raised-button color="primary">Register</button>
    </div>
</div> 