import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card2',
  templateUrl: './card2.component.html',
  styleUrls: ['./card2.component.css']
})
export class Card2Component implements OnInit {

  @Input("data")
  data: any;
  
  constructor() { }

  ngOnInit(): void {
    console.log("Inside Card1")
    console.log(this.data)
    console.log("Inside Card1")
  }

}
