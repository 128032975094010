import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  //baseUrl = "https://nazal.in/digi/";
  baseUrl = "https://6141990d357db50017b3db83.mockapi.io/api/v1/"

  login(username, password){
    return this.http.get<User>(this.baseUrl + 'user');

  }
}