import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';

const googleLogoURL = 
"https://raw.githubusercontent.com/fireflysemantics/logo/master/Google.svg";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  username: string;
  password:string;

  constructor (
    private userService: UserService,
    private appComponent : AppComponent,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer) {
      this.matIconRegistry.addSvgIcon(
        "googleLogo",
        this.domSanitizer.bypassSecurityTrustResourceUrl(googleLogoURL)
      );
    }


  ngOnDestroy(): void {
    this.appComponent.showToolbar()
  }

  ngOnInit(): void {
    this.appComponent.hideToolbar()
  }

  login(){
    this.userService.login(this.username, this.password).subscribe(data =>{
      localStorage.setItem("userDetails", JSON.stringify(data));
      if(localStorage.getItem("productData")){
        var productData = JSON.parse(localStorage.getItem("productData"));
        this.router.navigate(['/product-single'], {queryParams: { id: productData.productId }, relativeTo:this.route});
      } else {
        this.router.navigate(['/products']);
      }      
    })
  }

}
