<div style="background-color: red;" *ngIf="data">{{data.name}}</div>
<!-- 
<table id="table" class="table table-striped"> 
    <thead>
        <tr>
        <th>Firstname</th>
        <th>Lastname</th>
        <th>Email</th>
        </tr>
    </thead>
    <tbody>
        <tr>
        <td><a href="https://google.com">John</a></td>
        <td>Doe</td>
        <td>john@example.com</td>
        </tr>
        <tr>
        <td>Mary</td>
        <td>Moe</td>
        <td>mary@example.com</td>
        </tr>
        <tr>
        <td>July</td>
        <td>Dooley</td>
        <td>july@example.com</td>
        </tr>
    </tbody>
</table>
<button (click)="download()">Download PDF</button> -->