import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Utilities } from '../Utilities'

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.css']
})
export class PreviewDialogComponent implements OnInit {

  constructor(private router: Router, public dialogRef: MatDialogRef<PreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  forwardToLoginPage(){  
    localStorage.setItem("productData", JSON.stringify(this.data))
    this.router.navigate(['user/login']);
  }
  
  isLoggedIn(){
    return Utilities.isLoggedIn()
  }
}
