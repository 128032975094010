<div class="loginFullSection" color="primary">
    <div class="loginTopSection">
        <h2>Sign In</h2>
        <p>Lorem ipsum is simply dummy content to write anywhere on your website</p>
    </div>
    <div class="loginFormSection">
        <mat-form-field appearance="fill" >
            <mat-label>Username</mat-label>
            <input name="username" [(ngModel)]="username" matInput>
        </mat-form-field>
        <mat-form-field appearance="fill" >
            <mat-label>Password</mat-label>   
            <input name="password" [(ngModel)]="password" matInput>
        </mat-form-field>
        <div class="forgotPasswordLink">Forgot Password?</div>
        <button (click)="login()" class="loginBtn" mat-raised-button color="primary">Login</button>
        <button class="loginBtn googleLoginBtn"  mat-button style="padding: 1rem;">
            <mat-icon style="width: 50px; height:30px"
                      svgIcon="googleLogo"></mat-icon> Login With Google
        </button>
        
        <button class="loginBtn googleLoginBtn"  mat-button style="padding: 1rem;">
            <mat-icon class="fbBtn">facebook</mat-icon>
            Login With Facebook
        </button>
    </div>
</div> 