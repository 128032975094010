<br/>
<mat-grid-list cols="2">
    <mat-grid-tile  *ngFor="let product of productList">
        <mat-card (click)="openSinglePage(product)" class="example-card">
            <img mat-card-image [src]="product.imageUrl" [alt]="product.name">
            <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>{{product.name}}</mat-card-title>
                <mat-card-subtitle>Dog Breed</mat-card-subtitle>
            </mat-card-header>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>
