import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from 'src/app/app.component';

const googleLogoURL = 
"https://raw.githubusercontent.com/fireflysemantics/logo/master/Google.svg";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  
  constructor (
    private appComponent: AppComponent,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
          this.matIconRegistry.addSvgIcon(
      "googleLogo",
      this.domSanitizer.bypassSecurityTrustResourceUrl(googleLogoURL)
      );
      this.matIconRegistry.addSvgIcon(
        "facebookLogo",
        this.domSanitizer.bypassSecurityTrustResourceUrl(googleLogoURL)
        );
    }


  ngOnDestroy(): void {
    this.appComponent.showToolbar()
  }

  ngOnInit(): void {
    this.appComponent.hideToolbar()
  }


}
