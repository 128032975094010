<div *ngFor="let repeatingField of data.repeatingFields" class="repeatingFieldItem">
    <mat-form-field appearance="fill" *ngIf="repeatingField.type == 'text'">
        <mat-label>{{repeatingField.label}}</mat-label>
        <input [value]="data.itemData && data.itemData[repeatingField.name] ? data.itemData[repeatingField.name] : ''" (change)="onValueChange(repeatingField.name, $event.target.value)" matInput>
    </mat-form-field>
    <div class="ImageUploadField" *ngIf="repeatingField.type == 'image'">
        <button mat-raised-button color="accent">{{repeatingField.label}}</button>
        <input (change)="imageChange(repeatingField.name, $event)" type="file" class="form-control imageUploadRealField">
        <input (change)="inputChanged(repeatingField.name, $event.target.value)" [value]="data.itemData && data.itemData[repeatingField.name] ? data.itemData[repeatingField.name] : ''" hidden>
        <br/>
    </div>
</div>
<button *ngIf="!data.itemData" (click)="saveAndClose()" mat-raised-button color="accent">Add</button>
<button *ngIf="data.itemData" (click)="saveAndClose()" mat-raised-button color="accent">Save</button>
<button (click)="close()" style="margin-left: 10px;" mat-raised-button color="accent">Cancel</button>