import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { ProductStepData } from '../models/ProductStepData';
import { Template } from '../models/Template';
import { ProductService } from '../services/product.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { RepeaterDialogComponent } from '../repeater-dialog/repeater-dialog.component';
import { PreviewDialogComponent } from '../preview-dialog/preview-dialog.component';


@Component({
  selector: 'app-product-single',
  templateUrl: './product-single.component.html',
  styleUrls: ['./product-single.component.css']
})
export class ProductSingleComponent implements OnInit {

  productId: any = 1;
  productStepData: ProductStepData[];
  templateList: Template[]
  currentStep: String;
  finalData: any = {};
  finalDataStr: String;
  currentStepIndex: any;

  constructor(public dialog: MatDialog, private productService: ProductService, private appComponent: AppComponent, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getProductStepData();
    this.getTemplateList();

    if(localStorage.getItem("productData")){
      var productData = JSON.parse(localStorage.getItem("productData"));
      this.finalData = productData.previewData
    }
  }

  loadDefaultValues(){
    this.productStepData.forEach(stepData =>{
      stepData.fields.forEach(fieldData =>{
        if(fieldData.defaultValue != null && fieldData.defaultValue.length > 0){
          this.inputChanged(fieldData.name, fieldData.defaultValue)
        }
      })
    })
  }

  validatePageName(fieldName, value){
    this.showLoadingScreen()
    this.finalData[fieldName] = 'loading';
    this.productService.validatePageName(value).subscribe((data : Boolean) =>{
      if(data){
        this.finalData[fieldName] = value;
      } else {
        this.finalData[fieldName] = null;
      }
      this.finalDataStr = JSON.stringify(this.finalData);
      this.hideLoadingScreen()
    }, (error) =>{
      this.finalData[fieldName] = null;
      this.hideLoadingScreen()
    });
  } 

  getTitle(itemData){
    return itemData[Object.keys(itemData)[0]];
  }

  inputChanged(fieldName, value){
    this.finalData[fieldName] = value;
    this.finalDataStr = JSON.stringify(this.finalData);
    console.log(this.finalData)
  }

  arrayChanged(fieldName, subFieldName, index, value){
    debugger
    if(this.finalData[fieldName] == undefined){
      this.finalData[fieldName] = []
    }
    if(this.finalData[fieldName] == undefined){
      this.finalData[fieldName][index] = {}
    }
    this.finalData[fieldName][index][subFieldName] = value;
    this.finalDataStr = JSON.stringify(this.finalData);
    console.log(this.finalData)
  }

  setDefaultDataToFinalData(){ 
    this.productStepData.forEach(stepData => {
      stepData.fields.forEach(fieldData => {
        if(fieldData.type == 'repeater'){
          let res = [];
          let obj = {};
          fieldData.repeatingFields.forEach(element => {
            obj[element.name] = "";
          });
          res.push(obj)
          this.finalData[fieldData.name] = res;
        }
      });
    });
    this.finalDataStr = JSON.stringify(this.finalData);
  }

  addItemToList(field){
    let obj = {};
    field.repeatingFields.forEach(element => {
      obj[element.name] = "";
    });
    this.finalData[field.name].push(obj);
    this.finalDataStr = JSON.stringify(this.finalData);
  }

  deleteItemFromList(field, index){
    this.finalData[field.name].splice(index, 1);
    this.finalDataStr = JSON.stringify(this.finalData);
  }

  getSubFieldValue(fieldName, subFieldName, index){
    if(this.finalData[fieldName] == undefined){
      return "";
    } else if(this.finalData[fieldName][index] == undefined){
      return "";
    } else if(this.finalData[fieldName][index][subFieldName] == undefined){
      return "";
    }
    return this.finalData[fieldName][index][subFieldName]
  }

  imageChange(fieldName, event){
    this.showLoadingScreen()
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.productService.uploadFile(reader.result).subscribe((data) =>{
        this.inputChanged(fieldName, data)
        this.hideLoadingScreen()
      })        
    };
  } 

  getProductStepData() {
    this.showLoadingScreen()
    this.productService.getStepData(this.productId).subscribe((data : ProductStepData[]) =>{
      this.productStepData = data;
      this.currentStep = data[0].stepId;
      this.currentStepIndex = 0;
      this.loadDefaultValues();
      this.hideLoadingScreen()
      //this.setDefaultDataToFinalData();
      console.log(this.productStepData);
    });
  }

  getTemplateList() {
    this.productService.getListOfTemplates().subscribe((data : Template[]) =>{
      this.templateList = data;
    });
  }

  nextStep(){
    if(this.validateStep(this.currentStepIndex)){
      this.currentStepIndex = this.currentStepIndex + 1;
      this.currentStep = this.productStepData[this.currentStepIndex].stepId;
    }
  }

  prevStep(){
    this.currentStepIndex = this.currentStepIndex - 1;
    this.currentStep = this.productStepData[this.currentStepIndex].stepId;
  }

  validateStep(stepIndex){
    var isValid = true;
    this.productStepData[stepIndex].fields.every(stepField =>{
      if(stepField.required == true && (this.finalData[stepField.name] == null )){
        this.appComponent.showToast(stepField.errorMessage);
        isValid = false
        return false;
      }
      return true;
    });

    return isValid;
  }

  isFirstPage(){
    let index = this.productStepData.findIndex(item => item.stepId == this.currentStep);
    return index == 0 ? true : false;
  }

  isLastPage(){
    let index = this.productStepData.findIndex(item => item.stepId == this.currentStep) + 1;
    return index == this.productStepData.length ? true : false;
  }

  editItemFromList(field, itemData, i){
    const dialogRef = this.dialog.open(RepeaterDialogComponent, {
      width: '250px',
      data: {index: i, itemData: itemData, repeatingFields : field.repeatingFields}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != null){
        if(this.finalData[field.name] == undefined){
          this.finalData[field.name] = [];
        }
        this.finalData[field.name][i] = result
        this.finalDataStr = JSON.stringify(this.finalData);
      }
    });
  }

  openDialog(field): void {
    const dialogRef = this.dialog.open(RepeaterDialogComponent, {
      width: '90%',
      maxWidth: '400px',
      data: {repeatingFields : field.repeatingFields}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != null){
        if(this.finalData[field.name] == undefined){
          this.finalData[field.name] = [];
        }
        this.finalData[field.name].push(result);
        this.finalDataStr = JSON.stringify(this.finalData);
      }
    });
  }

  generatePreview(){
    const dialogRef = this.dialog.open(PreviewDialogComponent, {
      width: '90%',
      maxWidth: '400px',
      data: {previewData : this.finalData, productId: this.productId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != null){
        
      }
    });
  }
  
  showLoadingScreen(){
    this.appComponent.showLoadingScreen()
  }
  
  hideLoadingScreen(){
    this.appComponent.hideLoadingScreen()
  }

}
