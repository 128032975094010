import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-repeater-dialog',
  templateUrl: './repeater-dialog.component.html',
  styleUrls: ['./repeater-dialog.component.css']
})
export class RepeaterDialogComponent implements OnInit {

  finalObject = {};

  constructor(public dialogRef: MatDialogRef<RepeaterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private productService: ProductService) { }

  ngOnInit(): void {
  }

  onValueChange(fieldName, value){
    this.finalObject[fieldName] = value;    
  }

  close(){
    this.dialogRef.close();
  }
  
  saveAndClose(){
    if(this.validate()){
      this.dialogRef.close(this.finalObject);
    }
  }

  validate(){
    return true;
  }

  
  imageChange(fieldName, event){
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.productService.uploadFile(reader.result).subscribe((data) =>{
        this.onValueChange(fieldName, data)
      })        
    };
  } 
  
}
