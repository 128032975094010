<mat-vertical-stepper #stepper>
    <mat-step *ngFor="let stepData of productStepData" [label]="stepData.stepName">
        <div *ngFor="let field of stepData.fields" class="field">
            <div class="form-group">
                <div *ngIf="field" class="fieldData">
                    <!-- Default Value Input Text field, this field will be hidden -->
                    <mat-form-field appearance="fill" *ngIf="field.type == 'default'" style="display: none;">
                        <mat-label>{{field.label}}</mat-label>
                        <input (change)="inputChanged(field.name, $event.target.value)" [value]="finalData[field.name] != null ? finalData[field.name] : ''" matInput>
                    </mat-form-field>
                    
                    <!-- Input Text Field -->
                    <mat-form-field appearance="fill" *ngIf="field.type == 'text'">
                        <mat-label>{{field.label}}</mat-label>
                        <input (change)="inputChanged(field.name, $event.target.value)" [value]="finalData[field.name] != null ? finalData[field.name] : ''" matInput>
                    </mat-form-field>

                    <!-- File Upload Field -->
                    <div class="ImageUploadField" *ngIf="field.type == 'image'">
                        <button mat-raised-button color="accent">{{field.label}}</button>
                        <input (change)="imageChange(field.name, $event)" type="file" class="form-control imageUploadRealField">
                        <input (change)="inputChanged(field.name, $event.target.value)" [value]="finalData[field.name] != null ? finalData[field.name] : ''" hidden>
                        <br/>
                    </div>
                     
                    <!-- Input field with icon on the left. -->
                    <mat-form-field appearance="legacy" *ngIf="field.type == 'textWithIcon'">
                        <mat-label>{{field.label}}</mat-label>
                        <input (change)="inputChanged(field.name, $event.target.value)" matInput>
                        <mat-icon matPrefix>{{field.icon}}</mat-icon>
                    </mat-form-field>
                     
                    <!-- Repeater Fields. -->
                    <div *ngIf="field.type == 'repeater'">
                        <span class="repeaterFieldTitle">{{field.label}}</span>
                        <button class="itemBtn" (click)="openDialog(field)" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                            <mat-icon>add</mat-icon>
                        </button>

                        <mat-list role="list">  
                            <mat-list-item class="itemOne" *ngFor="let itemData of finalData[field.name]; let i = index" role="listitem">
                                {{getTitle(itemData)}}
                                <div class="itemBtns">
                                    <button class="itemBtn" (click)="deleteItemFromList(field, i)" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <button class="ItemBtn" (click)="editItemFromList(field, itemData, i)" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </div>                    

                    <!-- Check Page Availablity Field -->
                    <mat-form-field appearance="fill" *ngIf="field.type == 'pageAvailable'">
                        <mat-label>{{field.label}}</mat-label>
                        <input (change)="validatePageName(field.name, $event.target.value)" matInput>
                        <mat-icon *ngIf="finalData[field.name] != null && finalData[field.name] != 'loading'" matSuffix>check_circle</mat-icon>
                        <mat-icon *ngIf="finalData[field.name] == null" matSuffix>report_problem</mat-icon>
                        <mat-icon *ngIf="finalData[field.name] == 'loading'" matSuffix>autorenew</mat-icon>
                    </mat-form-field>
                    
                    <div *ngIf="field.type=='selectTemplate'" class="templates">                                            
                        <mat-grid-list cols="2">
                            <mat-grid-tile  *ngFor="let template of templateList">
                                <mat-card (click)="inputChanged(field.name, template.id)" class="example-card">
                                    <img mat-card-image [src]="template.imageUrl" [alt]="template.name">
                                    <mat-card-header>
                                        <div mat-card-avatar class="example-header-image"></div>
                                        <mat-card-title>{{template.name}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <button (click)="generatePreview()" *ngIf="finalData[field.name] != null" mat-raised-button color="accent">Generate Preview</button>
                    </div>
                </div>
            </div>
        </div>
    </mat-step>
  </mat-vertical-stepper> 

{{finalDataStr}}