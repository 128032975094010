import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Product } from '../models/Product';
import { ProductService } from '../services/product.service'

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {


  productList: Product[];

  constructor( private appComponent: AppComponent, private productService: ProductService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.getListOfProducts();
  }


  getListOfProducts() {
    this.appComponent.showLoadingScreen();
    this.productService.getListOfProducts().subscribe((data : Product[]) =>{
      this.productList = data;
      this.appComponent.hideLoadingScreen();
    });
  }

  openSinglePage(product: Product){
    this.router.navigate(['../product-single'], {queryParams: { id: product.id }, relativeTo:this.route});
  }

}
