import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from './globals'
import { Utilities } from './Utilities'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  toastMessage: string = null;
  globals : Globals;
  loadScreen: boolean = false;
  showSidenav: boolean = false;
  isShowToolbar: boolean = true;
  isSplashScreen: boolean = true;

  constructor(globals: Globals, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(){
    console.log(this.globals)

    var that = this;
    setTimeout(function(){
      that.isSplashScreen = false;
    }, 3000);

  }

  isLoggedIn(){
    return Utilities.isLoggedIn()
  }

  showLoadingScreen(){
    this.loadScreen = true;
  }
  
  hideLoadingScreen(){
    this.loadScreen = false;
  }

  showToast(message){
    this.toastMessage = message;
    setTimeout(() => {
      this.toastMessage = null;
    }, 2000);
  }

  toggleNav(){
    this.showSidenav = !this.showSidenav;
  }
  
  openPage(pageName){
    this.router.navigate([pageName]);
    this.toggleNav();
  }

  showToolbar(){
    this.isShowToolbar = true;
  }

  hideToolbar(){
    this.isShowToolbar = false;
  }
}
