import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductSingleComponent } from './product-single/product-single.component';
import { LoginComponent } from './user/login/login.component';
import { LogoutComponent } from './user/logout/logout.component';
import { ProfileComponent } from './user/profile/profile.component';
import { RegisterComponent } from './user/register/register.component';

const routes: Routes = [
  { 
    path: '',   
    redirectTo: 'product-list', 
    pathMatch: 'full' 
  },
  {
    path: "user/login",
    component: LoginComponent
  },
  {
    path: "user/register",
    component: RegisterComponent
  },
  {
    path: "user/profile",
    component: ProfileComponent
  },
  {
    path: "user/logout",
    component: LogoutComponent
  },
  {
    path: "products",
    component: ProductListComponent
  },
  {
    path: "product-single",
    component: ProductSingleComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
